
function inputData(){}

    $('form input').blur(function(){
        if(!$(this).val()) {
              $(this).removeClass('data');
        } else {
            $(this).addClass('data');
    }

});

$(document).ready(function(){

    $('#toggle').click(function(){
        if(!$(this).hasClass('open')){
            $(".flip-container").addClass("hover");
        }else{
            $(".flip-container").removeClass("hover");
        }
        $(this).toggleClass('open Close-flip');
	});
    $('#nav-icon3').click(function(){
		$(this).toggleClass('open');
	});


    //Menu Mobile
    var open = false;
    $("a#Hamburger").click(function(){
        //OPEN Menu:
        if(open == false){
                $("#Svg-menu").animate({
                    top: '80px'
                }, 300);
                setTimeout(function(){
                    $("nav").addClass("show");
                    $("nav ul").fadeIn();
                    $("article.content-nav").addClass("Mobile-logo");
                    $("article.Hero").fadeOut();
                    $("a#Hamburger").addClass("close");
                    $("body").addClass("no-scroll");
                }, 300);
                open = true;
            }
            //Close Menu:
            else {
                $("#Svg-menu").animate({
                    top: '830px'
                }, 500);
                setTimeout(function(){
                    $("nav").removeClass("show");
                    $("nav ul").fadeOut("slow");
                    $("article.content-nav").removeClass("Mobile-logo");
                    $("article.Hero").fadeIn();
                    $("a#Hamburger").removeClass("close");
                    $("body").removeClass("no-scroll");
                }, 700);
                open = false;
            }
            return false;
    });

    //Anchor script
    $(document).on('click', '.anchor', function(event){
        var $window = $(window);
        var windowsize = $window.width();
        var hash = this.hash;
        if ((this.hash !== "") && (windowsize >= 600)) {
          event.preventDefault();
          $('html, body').animate({
            scrollTop: $(hash).offset().top
          }, 800, function(){
            window.location.hash = hash;
          });
        }
        else {

            $("body").removeClass("no-scroll");
            $("nav").removeClass("show");
            $("nav ul").fadeOut();
            $("article.content-nav").removeClass("Mobile-logo");
            $("article.Hero").fadeIn();
            $("a#Hamburger").removeClass("close");
            $("#Svg-menu").css("top", "830px");
            setTimeout(function(){
                $('html, body').animate({
                  scrollTop: $(hash).offset().top
                }, 800, function(){
                  window.location.hash = hash;
                });
            }, 800);
            open = false;
        }
        return false;
    });

    inputData();

    $('.slider-four').slick({
      dots: false,
      infinite: true,
      speed: 300,
      slidesToShow: 4,
      slidesToScroll: 4,
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            dots: false,
            slidesToShow: 4,
            slidesToScroll: 4
          }
        },
        {
          breakpoint: 980,
          settings: {
            centerMode: true,
            centerPadding: '40px',
            infinite: true,
            dots: false,
            slidesToShow: 2,
            slidesToScroll: 2
          }
        },
        {
          breakpoint: 485,
          settings: {
            infinite: true,
            dots: false,
            arrows: false,
            centerMode: true,
            centerPadding: '40px',
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
    ]
    });


    function checkWidth() {
        var width = window.innerWidth || document.body.clientWidth;
        var $slick = $('.slider-six');
        if (width <= 485) {
            $slick.slick({
                dots: false,
                centerMode: true,
                centerPadding: '40px',
                slidesToShow: 1,
                slidesToScroll: 1
            });
        } else if (width <= 1244) {
            $slick.slick({
                centerMode: true,
                centerPadding: '40px',
                infinite: true,
                dots: false,
                slidesToShow: 2,
                slidesToScroll: 2
            });
        }
         else {
            // MAYOR A 1244
            $slick.slick({
              slidesPerRow: 3,
              rows: 2,
              dots: false,
              arrows: false,
              infinite: true,
              speed: 300,
          });
        }
    }
    checkWidth();
    $(window).resize(checkWidth);

    // validation
    validateForm();

});

$(document).on('submit', 'section#contacto form', function(e){
    e.preventDefault();

    // if validate is good go to send Mail
    if($('#contactForm').valid()){
        sendMail();
    }

});

$('form input').blur(function(){
   if(!$(this).val()) {
         $(this).removeClass('data');
   } else {
       $(this).addClass('data');
   }
});

var sendMail = function(){

    var data = {
        name : $('#contactForm input[name="fullname"]').val(),
        mail : $('#contactForm input[name="email"]').val()
    };

    // local
    //var host = '//localhost/propermail/app';

    // staging
    //var host = '//prueba.takeoff.com.uy/propermail';

    // prod
    var host = '//propermail.com/';

    $.ajax({
        url : host + '/mail/index.php',
        method : 'POST',
        data : data,
        beforeSend : function(xhr){
            $('form .Box .send').attr('value', 'Enviando...');
        }
    }).done(function(result){

        var res = $.parseJSON(result);

        if(res.error == false){
            $('form .Box .send').remove();
            $('form .Box .result').html(res.message);
        }else {
            $('form .Box .errors').html(res.message);
        }

    }).fail(function(req, textStatus, errorThrown){
       alert('Ooops, something happened: ' + textStatus + ' ' +errorThrown);
    });

};

var validateForm = function(){
    $('#contactForm').validate({
        errorLabelContainer : ".errors",
        rules : {
            email : {
                required : true
            }
        },
        messages : {
            fullname : {
                required : "Completa todos los campos."
            },
            email : "Completa todos los campos."
        },
        groups: {
            data: "fullname email"
        }
    });
};
